<template>
  <div
    class="flex flex-wrap items-center justify-between px-3 mr-3 border-b option"
    :class="wrapperClass"
  >
    <div
      v-if="hasPrependSlot"
      class="mr-3 text-xs font-bold tracking-wide text-orange-500 uppercase"
    >
      <slot name="prepend" />
    </div>

    <div
      v-if="hasLabelSlot"
      class="items-center my-0 text-xs text-left text-gray-300 select-none option-label"
    >
      <slot name="label" />
<!--
      <span
        v-if="hasExtraSlot"
        class="text-xs italic text-gray-500"
        v-tooltip="extra"
      ><fa-icon icon="circle-info"/>
      </span> -->

      <span
        v-if="hasMetaSlot"
        class="block text-xs italic text-blue-500"
      >
        <slot name="meta" />
      </span>
    </div>

    <div
      v-if="hasInputSlot"
      class="text-xs text-right text-white option-toggle"
      :class="{'w-full' : !hasLabelSlot}"
    >
      <slot name="input" />
    </div>
  </div>
</template>
<script>
export default {
  name: 'InputLayout',

  props: ['isShort', 'isNested'],

  computed: {

    wrapperClass () {
      let base = ''
      if (this.isShortInput) base = 'py-2'
      else base = 'pt-2 pb-2'

      if (this.isNestedInput) base = base + ' bg-gray-700 border-gray-800 pl-6'
      else base = base + ' border-gray-700'

      return base
    },

    isShortInput () {
      return (this.isShort === true)
    },

    isNestedInput () {
      return (this.isNested === true)
    },

    hasPrependSlot () {
      return !!this.$slots['prepend']
    },

    hasExtraSlot () {
      return !!this.$slots['extra']
    },

    hasMetaSlot () {
      return !!this.$slots['meta']
    },

    hasExtraOrMetaSlot () {
      return this.hasExtraSlot || this.hasMetaSlot
    },

    hasInputSlot () {
      return !!this.$slots['input']
    },

    hasLabelSlot () {
      return !!this.$slots['label']
    }
  }
}
</script>
