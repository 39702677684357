<template>
  <Layout>
    <template v-slot:label>
      {{ labelComputed }}
    </template>
    <template v-slot:extra>
      {{ extraComputed }}
    </template>
  </Layout>
</template>
<script>
import inputs from './InputMixin'

export default {
  name: 'InfoInput',

  mixins: [inputs]
}
</script>
