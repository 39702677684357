import Layout from './Layout'

export default {
  components: {
    Layout
  },

  props: ['row', 'valueDynamic'],

  data() {
    return {
      model: this.row.value,
      privateFloatModel: this.row.value,

      locked: true,
      hasChanges: false,

      model0: null
    }
  },

  mounted() {
    this.model0 = this.model
  },

  computed: {
    labelComputed() {
      if (this.row.label !== undefined) return this.row.label
      return null
    },

    valueComputed() {
      if (this.row.value !== undefined) return this.row.value
      return null
    },

    extraComputed() {
      if (this.row.extra !== undefined) return this.row.extra
      return null
    },

    isShortInput() {
      return this.row.short !== undefined && this.row.short === true
    },

    isDisabled() {
      return this.row.disabled !== undefined && this.row.disabled === true
    },

    inputMin() {
      if (this.row.min || this.row.min === 0) return this.row.min
      return -Infinity
    },

    inputMax() {
      if (this.row.max) return this.row.max
      return Infinity
    },

    inputStep() {
      if (this.row.step) return this.row.step
      return 0.0001
    },

    isLockable() {
      return this.row.lockable === true
    },

    isSimple() {
      return this.row.simple !== undefined && this.row.simple === true
    },

    isNested() {
      return this.row.nested !== undefined && this.row.nested === true
    },

    filteredFloatModel() {
      // Filters the value to be within the min and max
      if (this.privateFloatModel === null) return null

      // Filter out characters that are not numbers, decimals or negative signs
      if (/[^0-9.-]/g.test(this.privateFloatModel)) {
        return this.model
      }

      if (
        (this.inputMin || this.inputMin === 0) &&
        this.privateFloatModel < this.inputMin
      ) {
        return this.inputMin
      }

      if (this.inputMax && this.privateFloatModel > this.inputMax) {
        return this.inputMax
      }

      return this.privateFloatModel
    }
  },

  methods: {
    handleChange(value) {
      this.hasChanges = true
      this.$emit('change', value)
    }

    // reset () {
    //   this.model = Object.assign(this.model, this.model0)
    //   this.$emit('change', this.model)
    //   this.hasChanges = false
    // }
  },

  watch: {
    row: {
      handler: function (value) {
        if (value.value) {
          if (typeof this.model !== typeof value.value) {
            // Try to convert the value to the model's type
            // as this this mixin is used across several types of inputs
            // and typing can get mixed up
            try {
              if (typeof this.model === 'number') {
                value.value = Number(value.value)
              } else if (typeof this.model === 'string') {
                value.value = value.value.toString()
              } else if (typeof this.model === 'boolean') {
                value.value = Boolean(value.value)
              } else {
                return
              }
            } catch (e) {
              return
            }
          }

          this.model = value.value
          if (typeof value.value === 'number') {
            this.privateFloatModel = value.value
          }
        }
      },
      deep: true
    },

    model: {
      handler: 'handleChange'
    },

    privateFloatModel: {
      handler: function (value) {
        // Ensures the value is within the min and max because
        // the parent is only alerted when model changes,
        // so we only change that when the input is valid
        if (this.filteredFloatModel !== value) {
          // Reset the user visible input to be within the range
          this.privateFloatModel = this.filteredFloatModel
        } else {
          // Alert the parent of a successful change
          this.model = value
        }
      }
    }

    // valueDynamic (val) {
    //   console.log('[input mixin] value dynamic has changed to - ', val)
    //   if (this.model !== val) {
    //     console.log('This model not === val, updating', this.model)
    //     this.model = val
    //   }
    // }
  }
}
